<template>
  <div>
    <div id="container" class="container">
      <div class="conteudo">
        <img src="../assets/img/conta-tiktok.svg">
        <h1>Conta TikTok</h1>
        <div class="info-conta">
          <div class="tiktok-logo">
            <img src="../assets/img/tiktok-symbol.svg">
          </div>
          <div class="info-block">
            <div class="information">
              <p class="title">Conta TikTok:</p>
              <p class="value">
                <template v-if="!isLoading">{{ email }} <img src="../assets/img/mdi-check-green.svg" style="margin-left: 0.5rem"></template>
                <template v-else><i class="fas fa-spinner fa-pulse"></i></template>
              </p>
            </div>
            <div class="information">
              <p class="title">Conta Business:</p>
              <p class="value">
                <template v-if="!isLoading">{{ id }} <img src="../assets/img/mdi-check-green.svg" style="margin-left: 0.5rem"></template>
                <template v-else><i class="fas fa-spinner fa-pulse"></i></template>
              </p>
            </div>
            <div class="information">
              <p class="title">Nome da sua Loja:</p>
              <p class="value">
                <template v-if="!isLoading">{{ company }}</template>
                <template v-else><i class="fas fa-spinner fa-pulse"></i></template>
              </p>
            </div>
            <div class="information">
              <p class="title">URL da Loja:</p>
              <p class="value">
                <template v-if="!isLoading">{{ url }}</template>
                <template v-else><i class="fas fa-spinner fa-pulse"></i></template>
              </p>
            </div>
            <div class="information">
              <p class="title">Endereço da Loja:</p>
              <p class="value">
                <template v-if="!isLoading">{{ address }}<br>{{ country }}</template>
                <template v-else><i class="fas fa-spinner fa-pulse"></i></template>
              </p>
            </div>
          </div>
        </div>
        <b-button v-if="!isLoading" v-on:click="doDelete()" variant="outline-danger" style="margin-bottom: 5rem">Desconectar conta</b-button>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
      </div>
      <alert-error v-if="error">
        <template v-slot:alert-error>
          {{msgErrorAlert}}
        </template>
      </alert-error>
    </div>
  </div>
</template>

<script>
import ConfirmDialogue from './ConfirmDialogue.vue'

export default {
  name: 'ContaTikTok',
  components: { ConfirmDialogue},
  mounted() {
    document.title = 'Configurações'

    let storeId = this.$cookies.get('Authorization')

    this.$http.get('tiktok/business/store/status', {headers: {'Authorization': storeId}})
        .then(res => {
          this.email = res.data.email
          this.id = res.data.id
          this.company = res.data.company
          this.url = res.data.url
          this.address = res.data.address
          this.country = res.data.country
          this.isLoading = false
        }).catch(error => {
      if (error.response) {
        console.log(error.response.status);
        this.msgErrorAlert = error.response.data.message;
        this.error = true;
      }
    });
  },
  data: function () {
    return {
      isLoading: true,
      email: null,
      id: null,
      company: null,
      url: null,
      address: null,
      country: null,
      error: false,
      msgErrorAlert: "",
    }
  },
  computed: {
  htmlReturningFn: function ()  {
    return `Após desconectar sua conta todos os dados <b>serão possíveis ser visualizados APENAS no TikTok.</b>`
  }},
  methods: {
    async doDelete() {
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Desconectar conta?',
        message: this.email + " ("+ this.id + ")",
        messageExtra: this.htmlReturningFn,
        okButton: 'Desconectar conta',
        msgError: ''
      })

      if (ok) {
        this.$refs.confirmDialogue.okButton = 'Desconectando...'
        let storeId = this.$cookies.get('Authorization')
        this.$http.post('/tiktok/disconnect', { }, {headers: {'authorization': storeId} })
            .then((res) => {
              this.$refs.confirmDialogue.okButton = "Conta desconectada"
              this.$refs.confirmDialogue.msgError = ''
              window.parent.location.href = res.data.urlCallback + '/admin/onboarding/dashboard';
            }).catch(error => {
          if (error.response) {
            this.$refs.confirmDialogue.msgError = 'Falha ao desconectar sua conta'
            console.log(error.response.data.message)
            this.$refs.confirmDialogue.okButton = 'Desconectar conta';
          }
        })
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  letter-spacing: 0;
}

div.container {
  flex-direction: column;
  max-width: initial;
}

div.conteudo {
  max-width: initial;
  padding-left: 1em;
  padding-top: 2em;
  width: 100%;
}

h1 {
  align-self: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 2rem auto;
}

div.info-conta {
  align-items: flex-start;
  display: flex;
}

div.info-conta .tiktok-logo img {
  margin-right: 1em;
}

div.info-conta p.title {
  color: #6C757D;
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 0;
}

div.info-conta p.value {
  font-weight: 700;
  color: #323A46;
}
</style>
