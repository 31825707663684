<template>
  <div>
    <TikTokLogo/>
    <TabBar index="configuracoes"/>
    <MsgAlert v-if="isMsgVisible">
      <template v-slot:title>
        {{ titleMsg }}
      </template>
      <template v-slot:msg>
        {{ textMsg }}
      </template>
    </MsgAlert>
    <ContaTikTok/>
  </div>
</template>

<script>
// @ is an alias to /src
import TikTokLogo from "@/components/TikTokLogo";
import TabBar from "@/components/TabBar";
import ContaTikTok from "@/components/ContaTikTok";
import MsgAlert from "@/components/MsgAlert";

export default {
  name: 'Configuracoes',
  beforeMount() {
    this.countCheckBlock();
  },
  data() {
    return {
      isMsgVisible: false,
      titleMsg: '',
      textMsg: '',
    }
  },
  components: {
    TikTokLogo,
    TabBar,
    ContaTikTok,
    MsgAlert,
  },
  methods: {
    countCheckBlock() {
      if (this.$cookies.get('blockedAccount') === 'block') {
        this.titleMsg = 'A sua conta no TikTok foi bloqueada.';
        this.textMsg = 'Suas campanhas e produtos não serão atualizados.'
        this.isMsgVisible = true;
      }
    }
  }
}
</script>
